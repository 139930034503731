import React, { Component } from "react"

import {
	CarouselProvider,
	Slide,
	Slider,
	ButtonBack,
	ButtonNext,
	Image
} from "pure-react-carousel"
import 'pure-react-carousel/dist/react-carousel.es.css'

import Layout from "../components/layout"

import * as styles from '../styles/pages/tutorials.module.css'
import * as styles_gallery from '../styles/pages/about-gallery.module.css'
import * as styles_slideshow from '../components/slideshow/style.module.css'

import image_videoholder from '../images/videoholder.png'


import image_nowplaying from "../images/tutorials/nowplaying.png"

import image_textarrow_left from "../images/tutorials/arrow-left.png"
import image_textarrow_right from "../images/tutorials/arrow-right.png"


import image_arrow_left from "../images/gallery/arrow-left.png"
import image_arrow_right from "../images/gallery/arrow-right.png"

import arrowleft from "../images/home/arrow-left.png"
import arrowright from "../images/home/arrow-right.png"


import videolist from "../content/tutoriallist.json"


export default class TutorialsPage extends Component {
	state = {
		width: 1446,
		height: 606,
		slideitemcount: 4,
		curvideoidx: 0,
		curvideokey: videolist[0].videos[0].key,
		curvideowriteup: videolist[0].videos[0].writeup
	}

	componentDidMount() {
		this.handleResize()
		window.addEventListener('resize', this.handleResize)
		//this.setImage(0);
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize)
	}

	handleResize = () => {
		var newitemcount = 4;
		if (window.innerWidth<= window.innerHeight) {
			newitemcount = 3;
		}
		this.setState(state => ({
			width: window.innerWidth,
			height: window.innerHeight,
			slideitemcount: newitemcount
		}));
	};

	handleLeftClick = () => {
		this.setVideoByIndex(this.state.curvideoidx-1);
	}
	handleRightClick = () => {
		this.setVideoByIndex(this.state.curvideoidx+1);
	}

	setVideoByIndex = (newidx) => {
		// Hardcode indices
		var newobj = {
			"videodata": null
		}
		if (newidx < 0) {
			newobj.videodata = videolist[2].videos[2];
		} else if (newidx < 4) {
			newobj.videodata = videolist[0].videos[newidx];
		} else if (newidx < 6) {
			newobj.videodata = videolist[1].videos[newidx-4];
		} else if (newidx < 9) {
			newobj.videodata = videolist[2].videos[newidx-6];
		} else {
			newobj.videodata = videolist[0].videos[0];
		}
		this.handleThumbnailClick(newobj);
	}

	handleThumbnailClick = ({videodata}) => {
		this.setState(state => ({
			curvideoidx: videodata.index,
			curvideokey: videodata.key,
			curvideowriteup: videodata.writeup
		}));
	}

	render() {
		return (
		  <Layout activePath='tutorials' pageTitle="Tutorials">
				<div className="bg-cement-tile text-centered">
			  		<br/>
				    <h1 class='font-size-large header-title fullblock-mobile-only'>Tutorials</h1>
			  		<br/>
			  		<div className={styles.videonavholder}>
				  		<input type="image" onClick={this.handleLeftClick} className={styles.arrow+" displayinline-desktop-only "+styles.arrowleft}  src={image_textarrow_left} alt="<" />
				  		<input type="image" onClick={this.handleLeftClick} className={styles.arrow+" displayinline-mobile-only "+styles.arrowleft}  src={image_arrow_left} alt="<" />
						<div className={styles.videoholder}>
							<img src={image_videoholder} alt="" className="fullblock" />
							<iframe title='About Konstrukt' src={"https://www.youtube.com/embed/"+this.state.curvideokey} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</div>
				  		<input type="image" onClick={this.handleRightClick} className={styles.arrow+" displayinline-desktop-only "+styles.arrowright}  src={image_textarrow_right} alt=">" />
				  		<input type="image" onClick={this.handleRightClick} className={styles.arrow+" displayinline-mobile-only "+styles.arrowright}  src={image_arrow_right} alt=">" />
				  	</div>
					<p className={styles.writeholder}  dangerouslySetInnerHTML={{
              __html: this.state.curvideowriteup }} />
				</div>
				{
					videolist.map((data, index)=> {
						return (
							<>
								<div className={styles.thumbnailtitle+" "+styles_gallery.slidejumpertitle}>
							  		<span></span>
									<div className="font-size-medium">&nbsp;{data.title}&nbsp;</div>
								</div>
								<div className={styles.thumbnailholder+" text-centered "+ (this.state.width > this.state.height?styles_slideshow.slidewide:styles_slideshow.slidetall)}>
								{
									data.videos.length <= 3 ?
										(
											data.videos.map((videodata, videoindex)=> {
												return (
													<div className={styles.thumbnail+" "+styles["thumbnail"+videoindex]}>
														<input type="image" className={styles.thumbnailimage} src={"https://img.youtube.com/vi/"+videodata.key+"/mqdefault.jpg"} alt={data.title+" "+videoindex} onClick={()=>{
																this.handleThumbnailClick({videodata})
															}}  />
														{this.state.curvideoidx===videodata.index && <img src={image_nowplaying} alt="" className={styles.thumbnailactive} />}
													</div>
												)
											})
										)
									: <CarouselProvider
										naturalSlideWidth={1920/1080}
										naturalSlideHeight={1}
										totalSlides={data.videos.length}
										isPlaying={false}
										hasMasterSpinner={true}
										className={styles_slideshow.container}
										infinite={true}
										visibleSlides={this.state.slideitemcount}
									>
										<Slider className={styles.thumbnailslider}>
										{
											data.videos.map((videodata, videoindex)=> {
												return (
													<Slide index={videoindex} className={styles.thumbnail+" "+styles["thumbnail"+videoindex]}>
														<Image type="image" className={styles.thumbnailimage} src={"https://img.youtube.com/vi/"+videodata.key+"/mqdefault.jpg"} alt={data.title+" "+videoindex} onClick={()=>{
																this.handleThumbnailClick({videodata})
															}}  />
														{this.state.curvideoidx===videodata.index && <img src={image_nowplaying} alt="" className={styles.thumbnailactive} />}
													</Slide>
												)
											})
										}
										</Slider>
										{
											this.state.slideitemcount < data.videos.length &&
											<>
												<ButtonBack
													className={`${styles_slideshow.buttonBack} ${styles_slideshow.button}`}
													onClick={this.disableAnimation}
												>
													<img src={arrowleft} alt='<'/>
												</ButtonBack>
												<ButtonNext
													className={`${styles_slideshow.buttonNext} ${styles_slideshow.button}`}
													onClick={this.disableAnimation}
												>
													<img src={arrowright} alt='>'/>
												</ButtonNext>
											</>
										}
									</CarouselProvider>
								}
								</div>
							</>
						)

					})
				}
				<br/>
		  </Layout>
		)
	}

}