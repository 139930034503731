// extracted by mini-css-extract-plugin
export var arrow = "tutorials-module--arrow--ed918";
export var arrowleft = "tutorials-module--arrowleft--abbad";
export var arrowright = "tutorials-module--arrowright--9be0a";
export var thumbnail = "tutorials-module--thumbnail--7f751";
export var thumbnail0 = "tutorials-module--thumbnail0--d968b";
export var thumbnailactive = "tutorials-module--thumbnailactive--abe6b";
export var thumbnailholder = "tutorials-module--thumbnailholder--fccd0";
export var thumbnailimage = "tutorials-module--thumbnailimage--0b4a2";
export var thumbnailslider = "tutorials-module--thumbnailslider--57f4f";
export var thumbnailtitle = "tutorials-module--thumbnailtitle--1df34";
export var videoholder = "tutorials-module--videoholder--f2a59";
export var videonavholder = "tutorials-module--videonavholder--b94c2";
export var writeholder = "tutorials-module--writeholder--c156a";